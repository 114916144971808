import getConfig from 'next/config';

export function getQueryProviderProps() {
  const { publicRuntimeConfig = {}, serverRuntimeConfig = {} } = getConfig() || {};
  return {
    appId: publicRuntimeConfig.DX_AUTH_API_CUSTOMER_APP_ID,
    incompleteLoggingEnabled: publicRuntimeConfig.RUMCS_CAPTURE_CUSTOM_ERRORS === 'enabled',
    gqlEndpoints: {
      browser: publicRuntimeConfig.DX_GRAPHQL_CLIENT_URI,
      server: serverRuntimeConfig.DX_GRAPHQL_SERVER_URI,
    },
    authEndpoints: {
      browser: publicRuntimeConfig.DX_AUTH_API_CLIENT_URI,
      server: serverRuntimeConfig.DX_AUTH_API_SERVER_URI,
    },
  };
}
