import type { ExecutionResult, GraphQLError, NamedTypeNode } from 'graphql';

type DXErrorNotification = {
  code: number;
  fields: string[];
  message: string;
};

type DXExtension = {
  logSearch: string;
  operationName: string;
  code?: string;
  notifications?: DXErrorNotification[];
};

export type DXGraphQLError = Omit<GraphQLError, 'extensions'> & {
  code: number;
  context: string;
  notifications: DXErrorNotification[];
  extensions: DXExtension;
  'reference-error'?: string;
};

export type DXError = GraphError | ServerAuthError;

export type DXExecutionResult = Omit<ExecutionResult, 'errors'> & {
  extensions?: DXExtension;
  errors?: ReadonlyArray<DXGraphQLError>;
  __info__?: QueryDebugInfo;
};
export type DXNamedNode = NamedTypeNode & { value: string };

export type OperationErrors = 'Unauthorized' | 'Forbidden' | 'Not Found';

export class GraphError extends Error {
  data: DXExecutionResult['data'];
  graphQLErrors: DXGraphQLError[];

  public constructor(result: DXExecutionResult) {
    const graphQLErrors = result.errors as DXGraphQLError[];
    super(graphQLErrors?.[0]?.message || 'Unknown Error');
    this.data = result.data;
    this.graphQLErrors = graphQLErrors;
  }
}

export class ServerAuthError extends Error {
  statusCode: number;

  public constructor(statusCode: number, message?: string) {
    super(message || 'Authorization Error');
    this.statusCode = statusCode;
  }
}

export interface QueryDebugInfo {
  dxCompleteness: '0' | '1';
  isSSR: boolean;
  operationName: QueryDocument['operationName'];
  originalOpName: QueryDocument['originalOpName'];
}

export type QueryDocument = {
  operationString: string;
  operationName: string;
  originalOpName: string;
};

export type QueryVariables = Record<string, unknown> | undefined;
export type QueryKeyArgs = [QueryDocument, QueryVariables];

export type QueryRequestArgs = {
  document: QueryDocument;
  variables?: QueryVariables;
};
