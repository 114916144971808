import type * as Types from './types';

import {
  useQuery,
  type QueryClient,
  type FetchQueryOptions,
  type UseQueryOptions,
} from '@tanstack/react-query';

export const FeatureConfigsDocument = {
  operationName: 'featureConfigs',
  operationString: `query featureConfigs($names: [String!]) {
  featureConfigs(names: $names) {
    name
    conditions
    config
  }
}`,
  originalOpName: 'featureConfigs',
};
export function useFeatureConfigsQuery<TData = Types.FeatureConfigsQuery, TError = unknown>(
  variables?: Types.FeatureConfigsQueryVariables,
  options?: Omit<UseQueryOptions<Types.FeatureConfigsQuery, TError, TData>, 'queryKey'>
) {
  return useQuery<Types.FeatureConfigsQuery, TError, TData>({
    queryKey: [FeatureConfigsDocument, variables],
    ...options,
  });
}
export function serverSideFeatureConfigsQuery(
  queryClient: QueryClient,
  variables?: Types.FeatureConfigsQueryVariables,
  options?: FetchQueryOptions<Types.FeatureConfigsQuery>
) {
  return queryClient.fetchQuery<Types.FeatureConfigsQuery>({
    queryKey: [FeatureConfigsDocument, variables],
    ...options,
  });
}
