import cx from 'classnames';

import { homeComponentNames } from '../componentMapping/homePageComponents';

import type { ThemeColors as GqlThemeColors, Maybe } from '@dx-ui/gql-types';
import type { MappedKey } from '../componentMapping/homePageComponents';
import type { LayoutData } from '../layout.types';

export type ThemeColors = {
  primary: string;
  primaryContrast: string;
  secondary: string;
  secondaryContrast: string;
  tertiary: string;
  tertiaryContrast: string;
};

export type TWithCustomTheme<T> = T & { wrapperClass?: string };

type LayoutComponents = 'alertBanner' | 'globalNav' | 'navShopForm' | 'footer';

type AvailableComponentTheme = MappedKey | LayoutComponents;

type CustomClassMap = { [K in AvailableComponentTheme]?: string | string[] };

export const className = {
  altPrimary: 'alt-custom-primary',
  altSecondary: 'alt-custom-secondary',
  altTertiary: 'alt-custom-tertiary',
  brandAltBrand: 'brand-alt',
  brandPrimary: 'brand-primary',
  buttonPrimaryContrast: 'button-custom-primary-contrast',
  colorPrimaryOverride: 'color-primary-override',
  headingBrand: 'heading-brand',
  navItemHoverBorder: 'nav-item-hover-border',
  navItemHoverPrimary: 'nav-item-hover-primary',
  navItemHoverSecondary: 'nav-item-hover-secondary',
  navItemHoverTertiary: 'nav-item-hover-tertiary',
  navLowerPrimary: 'nav-lower-primary',
  navLowerSecondary: 'nav-lower-secondary',
  navLowerTertiary: 'nav-lower-tertiary',
  primary: 'custom-primary',
  secondary: 'custom-secondary',
  tertiary: 'custom-tertiary',
  textAltPrimaryContrast: 'text-alt-primary-contrast',
} as const;

export const GU: CustomClassMap = {
  alertBanner: className.primary,
  globalNav: [
    className.primary,
    className.navItemHoverBorder,
    className.navItemHoverTertiary,
    className.navLowerSecondary,
    className.colorPrimaryOverride,
  ],
  [homeComponentNames.CELEBRITY_QUOTE]: className.primary,
  [homeComponentNames.FULL_WIDTH_IMAGE_CAROUSEL]: className.primary,
  [homeComponentNames.HALFANDHALF_CAROUSEL]: className.tertiary,
  [homeComponentNames.HONORS]: className.tertiary,
  [homeComponentNames.HOTEL_DESCRIPTION]: className.primary,
  [homeComponentNames.LOCATION_AND_TRANSPORTATION]: [
    className.primary,
    className.brandPrimary,
    className.textAltPrimaryContrast,
    className.colorPrimaryOverride,
  ],
  [homeComponentNames.PARTNERSHIP_INFO]: className.tertiary,
  [homeComponentNames.PROPERTY_ALERTS]: [className.primary, className.colorPrimaryOverride],
  [homeComponentNames.ROOMS_OVERVIEW]: [
    className.primary,
    className.altTertiary,
    className.buttonPrimaryContrast,
  ],
  [homeComponentNames.STICKY_SIDE_BY_SIDE]: [className.secondary, className.altTertiary],
  [homeComponentNames.UTILITY_RAIL]: ['text-text', '!border-text'],
  [homeComponentNames.VERTICAL_POLICY_TABS]: className.tertiary,
  navShopForm: [className.tertiary, className.colorPrimaryOverride],
} as const;

const OU: CustomClassMap = {
  alertBanner: ['text-primary', 'bg-quarternary'],
  globalNav: [className.primary],
  [homeComponentNames.FULL_WIDTH_IMAGE_CAROUSEL]: className.tertiary,
  [homeComponentNames.HALFANDHALF_CAROUSEL]: className.tertiary,
  [homeComponentNames.HONORS]: className.secondary,
  [homeComponentNames.HOTEL_DESCRIPTION]: className.primary,
  [homeComponentNames.LOCATION_AND_TRANSPORTATION]: className.primary,
  [homeComponentNames.PROPERTY_ALERTS]: ['text-primary', 'bg-quarternary'],
  [homeComponentNames.ROOMS_OVERVIEW]: className.primary,
  [homeComponentNames.UTILITY_RAIL]: ['text-primary', '!border-brand'],
  [homeComponentNames.VERTICAL_POLICY_TABS]: className.secondary,
  navShopForm: className.secondary,
} as const;

const LX: CustomClassMap = {
  alertBanner: className.tertiary,
  [homeComponentNames.FULL_WIDTH_IMAGE_CAROUSEL]: 'text-primary',
  [homeComponentNames.HONORS]: [className.primary, className.brandAltBrand],
  [homeComponentNames.HOTEL_DESCRIPTION]: [className.primary, className.headingBrand],
  [homeComponentNames.LOCATION_AND_TRANSPORTATION]: [className.secondary, className.brandAltBrand],
  [homeComponentNames.PROPERTY_ALERTS]: className.tertiary,
  [homeComponentNames.ROOMS_OVERVIEW]: className.primary,
  [homeComponentNames.STICKY_SIDE_BY_SIDE]: [className.secondary, className.altPrimary],
  [homeComponentNames.UTILITY_RAIL]: ['text-primary', '!border-brand'],
  [homeComponentNames.VERTICAL_POLICY_TABS]: [className.secondary, className.brandAltBrand],
  navShopForm: className.secondary,
} as const;

const componentBrandClassMap: { [brandCode: string]: CustomClassMap } = {
  GU,
  LX,
  OU,
} as const;

export const isValidTheme = (theme: Maybe<Pick<GqlThemeColors, keyof ThemeColors>> | undefined) =>
  Object.keys(theme ?? {}).length === 6 &&
  Object.values(theme ?? {}).every((value) => /^#[0-9A-F]{6}$/i.test(value ?? ''));

export const mapCustomClassesToComponent = (
  componentName: AvailableComponentTheme,
  brandCode: NonNullable<LayoutData['brandCode']>
) => {
  if (Object.hasOwn(componentBrandClassMap, brandCode)) {
    const className =
      componentBrandClassMap[brandCode as keyof typeof componentBrandClassMap][componentName];

    return cx(className);
  }

  return '';
};

export const minifyInlineStyles = (styles: string) =>
  styles
    .replace(/\/\*.*?\*\//g, '')
    .replace(/\s+/g, ' ')
    .trim();
