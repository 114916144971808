import type { PagePropsUseClients } from './use-clients';
import { useClients } from './use-clients';
import { AuthProvider } from '@dx-ui/framework-auth-provider';
import type { DehydratedState } from '@tanstack/react-query';
import { HydrationBoundary, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import type { QueryDebugInfo } from './types';
import { useQueryIncomplete } from './use-query-incomplete';

export type QueryProviderProps = {
  devtoolsOptions?: React.ComponentProps<typeof ReactQueryDevtools>;
  dehydratedQueryState?: DehydratedState;
  onIncompleteQuery?: (query: QueryDebugInfo) => {
    reportIncomplete: boolean;
  };
  incompleteLoggingEnabled?: boolean;
} & PagePropsUseClients;

export function QueryProvider({
  children,
  dehydratedQueryState,
  devtoolsOptions,
  onIncompleteQuery,
  incompleteLoggingEnabled,
  ...useClientProps
}: React.PropsWithChildren<QueryProviderProps>) {
  const { queryClient, authClient } = useClients(useClientProps);

  useQueryIncomplete({ queryClient, onIncompleteQuery, incompleteLoggingEnabled });

  return (
    <AuthProvider authClient={authClient}>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={dehydratedQueryState}>{children}</HydrationBoundary>
        <ReactQueryDevtools initialIsOpen={false} {...devtoolsOptions} />
      </QueryClientProvider>
    </AuthProvider>
  );
}
