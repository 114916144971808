/* eslint-disable no-console */
import get from 'lodash/get';
import set from 'lodash/set';
import unset from 'lodash/unset';
import isEmpty from 'lodash/isEmpty';
import type { DigitalData, Satellite } from '../types/types';

export type Options = {
  digitalData?: DigitalData;
  server?: boolean;
  debug?: boolean;
  seedFile?: string;
};

/**
 * Performs the tracking calls to Adobe Tag Manager.
 * Pulled from https://github.com/nfl/react-metrics/blob/master/examples/vendors/AdobeTagManager.js
 * @module AdobeTagManager
 * @class
 * @internal
 */
export default class AdobeTagManager {
  options: Options;
  digitalData: DigitalData;
  pageName: string;
  name: string;
  _promise?: Promise<Satellite | undefined>;
  _satellite?: Satellite;
  constructor(options: Options = {}) {
    this.options = options;
    this.digitalData = options.digitalData || {};
    this.pageName = '';
    this.name = 'Adobe Tag Manager';
    // TODO - Will reliably turn on onmiture debugging vs (_satellite.setDebug(true))
    if (!this.options.server) {
      localStorage.setItem('sdsat_debug', String(this.options.debug));
    }
  }

  public setEventInfo(...args: string[]) {
    this._addEventInfo(...args);
  }

  public track(eventName: string, params?: unknown, shouldAddEvent?: boolean) {
    if (shouldAddEvent) {
      this._addEventInfo(eventName);
    }
    if (!this.options.server) {
      return new Promise((resolve, reject) => {
        this._load()
          .then((satellite) => {
            this._satellite = this._satellite || satellite;
            this._track(eventName, params);
            resolve({
              eventName,
              params,
            });
          })
          .catch((error) => {
            console.error('Omniture: Failed to load seed file', error);
            reject(error);
          });
      });
    } else {
      return false;
    }
  }

  _track(eventName: string, params: unknown) {
    const data = isEmpty(params) ? this.digitalData : params;
    window.digitalData = data as DigitalData;
    if (this._satellite) {
      this._satellite.track?.(eventName);
    }
  }

  _load() {
    this._cleanAndSetData();
    return (
      this._promise ||
      (this._promise = new Promise((resolve, reject) => {
        if (window._satellite && window._satellite.track) {
          resolve(window._satellite);
          this.dispatchReady();
        } else {
          const seedScript =
            (document.querySelector('[data-name="launch"]') as HTMLScriptElement) ||
            document.createElement('script');

          seedScript.onload = () => {
            resolve(window._satellite);
            this.dispatchReady();
          };

          seedScript.onerror = (error) => {
            reject(error);
          };

          seedScript.src = String(this.options.seedFile);
          seedScript.setAttribute('async', 'true');
          document.head.appendChild(seedScript);
        }
      }))
    );
  }

  _addEventInfo(...names: string[]) {
    names = Array.isArray(names) ? names : [names];
    const events = names.map((name) => ({ eventInfo: { eventAction: name } }));
    this.digitalData.event?.push(...events);
  }

  _resetEvents() {
    this.digitalData.event = [];
  }

  _set(path: string, value: unknown) {
    set(this.digitalData, path, value);
  }

  _unset(path: string, _value?: string) {
    unset(this.digitalData, path);
  }

  /**
   * Nov 7, 2018: the following fields are what Hilton's custom Adobe Launch script will expect.
   * Check if the required fields exist and initialize them if they do not.
   * @method _cleanAndSetData
   * @private
   */
  _cleanAndSetData() {
    const propertySearchLocationPath = 'page.attributes.propertySearchLocation';
    const propertySearchLocation = get(this.digitalData, propertySearchLocationPath);
    if (propertySearchLocation === undefined) {
      this._set(propertySearchLocationPath, '');
    }

    const propertySearchDateInfoPath = 'page.attributes.propertySearchDateInfo';
    const propertySearchDateInfo = get(this.digitalData, propertySearchDateInfoPath);
    if (propertySearchDateInfo === undefined) {
      this._set(propertySearchDateInfoPath, '00:00:00:00');
    }

    const productIDPath = 'product[0].productInfo.productID';
    const productID = get(this.digitalData, productIDPath);
    if (productID === undefined) {
      this._set('product', [{ productInfo: { productId: '' } }]);
    }

    window.digitalData = this.digitalData;
  }

  private dispatchReady() {
    window.dispatchEvent(new CustomEvent('metrics-ready'));
  }
}
