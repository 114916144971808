import type { TConditionalRender } from '../../helpers/componentMapper';
import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import { DO_NOT_RENDER } from '../../helpers/constants';
import type { FullWidthVideoProps } from './full-width-video';

export const mapHomePageDataToFullWidthVideo = ({
  homepageData,
}: AvailableHomePageData): TConditionalRender<FullWidthVideoProps> => {
  if (homepageData?.videos.length && !homepageData.videos[0].source) {
    const selectedVideo = homepageData.videos[0];
    const captionTracks = selectedVideo.metadata.filter((data) => data.type === 'captionTrack');

    return {
      isAutoPlay: !!selectedVideo.autoPlay,
      videoUrl: selectedVideo.url,
      orientation: selectedVideo?.orientation ?? 'landscape',
      posterImageUrl: selectedVideo?.posterImageUrl ?? '',
      captionTracks,
    };
  }

  return DO_NOT_RENDER;
};
