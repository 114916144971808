import dynamic from 'next/dynamic';

import { mapHomePageDataToCelebrityQuote } from '../../components/celebrity-quote/dataMappers';
import { mapHomePageDataToDescriptionAmenities } from '../../components/description-amenities/dataMappers';
import { mapHomePageDataToEventInfo } from '../../components/events-info/dataMappers';
import { mapDataToFullWidthDynamicHero } from '../../components/full-width-dynamic-hero/dataMappers';
import { mapHomePageDataToFullWidthCarousel } from '../../components/full-width-image-carousel/dataMappers';
import { mapHomePageDataToFullWidthVideo } from '../../components/full-width-video/dataMappers';
import { mapHomePageDataToHalfAndHalfCarousel } from '../../components/half-and-half-carousel/dataMappers';
import { mapHomePageDataToHonors } from '../../components/honors/dataMappers';
import { mapHomePageDataToHotelDescription } from '../../components/hotel-description/dataMappers';
import { mapHomePageDataToHotelPolicies } from '../../components/property/hotel-policies/dataMappers';
import { mapHomePageDataToInspiredHero } from '../../components/inspired-hero/dataMappers';
import { mapHomePageDataToLocationAndTransportation } from '../../components/location-and-transportation/dataMappers';
import { mapHomePageDataToPartnershipInfo } from '../../components/partnership-info/dataMappers';
import { mapHomePageDataToPatchworkGrids } from '../../components/patchwork-grids/dataMappers';
import { mapHomePageDataToPropertyAlerts } from '../../components/alerts-property/dataMappers';
import { mapHomePageDataToRoomsOverview } from '../../components/rooms-overview/dataMappers';
import { mapHomePageDataToStayTourVideo } from '../../components/stay-tour-video/dataMappers';
import { mapHomePageDataToStickySideBySide } from '../../components/sticky-side-by-side-component/dataMappers';
import { mapHomePageDataToTabbedOverview } from '../../components/tabbed-overview/dataMappers';
import { mapHomePageDataToUtilityRail } from '../../components/utility-rail/dataMappers';
import { mapHomePageDataToVerticalPolicyTabs } from '../../components/vertical-policy-tabs/dataMappers';
import { mapHomePageDataToVoucherInfo } from '../../components/voucher-info/dataMappers';

import type CelebrityQuoteComponent from '../../components/celebrity-quote/CelebrityQuote';
import type DescriptionAmenitiesComponent from '../../components/description-amenities/description-amenities';
import type EventsInfoComponent from '../../components/events-info/events-info';
import type FullWidthDynamicHeroComponent from '../../components/full-width-dynamic-hero/full-width-dynamic-hero';
import type FullWidthHeroImageCarouselComponent from '../../components/full-width-image-carousel/full-width-image-carousel';
import type FullWidthVideoComponent from '../../components/full-width-video/full-width-video';
import type HalfAndHalfCarouselComponent from '../../components/half-and-half-carousel/HalfAndHalfCarousel';
import type HonorsComponent from '../../components/honors/honors';
import type HotelDescriptionComponent from '../../components/hotel-description/hotel-description';
import type HotelPoliciesComponent from '../../components/property/hotel-policies/HotelPolicies';
import type InspiredHeroComponent from '../../components/inspired-hero/InspiredHero';
import type LocationAndTransportationComponent from '../../components/location-and-transportation/location-and-transportation';
import type PartnershipInfoComponent from '../../components/partnership-info/partnership-info';
import type PatchworkGridsComponent from '../../components/patchwork-grids/PatchworkGrids';
import type PropertyAlertsComponent from '../../components/alerts-property';
import type RoomsOverviewComponent from '../../components/rooms-overview/RoomsOverview';
import type StayTourVideoComponent from '../../components/stay-tour-video/stay-tour-video';
import type StickySideBySideComponent from '../../components/sticky-side-by-side-component/StickySideBySideDesktopComponent';
import type TabbedOverviewComponent from '../../components/tabbed-overview/TabbedOverview';
import type UtilityRailComponent from '../../components/utility-rail/utility-rail';
import type VerticalPolicyTabsComponent from '../../components/vertical-policy-tabs/VerticalPolicyTabs';
import type VoucherInfoComponent from '../../components/voucher-info/voucher-info';

import type { GetHotelHomePageQuery } from '../../generated/types';
import type { LayoutData } from '../layout.types';

export type MappedKey = ValuesOf<typeof homeComponentNames>;
export type MappedComponents = Parameters<(typeof availableComponents)[MappedKey]>[0] | null;
export type AvailableHomePageData = {
  ctyhocn: string;
  homepageData: GetHotelHomePageQuery['hotel'];
  isStayTourVideoEnabled: boolean;
  layout: LayoutData;
  showMeetingsSimplified: boolean;
};

/**
 * This function should be used in place of a data mapper
 * in the `componentDataMappers` object when no external
 * data is required to render the component.
 * @returns null
 */
export const NO_MAPPING_REQUIRED = () => null;

const CelebrityQuote = dynamic(
  () => import('../../components/celebrity-quote/CelebrityQuote').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof CelebrityQuoteComponent;

const DescriptionAmenities = dynamic(
  () =>
    import('../../components/description-amenities/description-amenities').then(
      (module) => module.default
    ),
  {
    ssr: true,
  }
) as typeof DescriptionAmenitiesComponent;

const EventsInfo = dynamic(
  () => import('../../components/events-info/events-info').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof EventsInfoComponent;

const FullWidthDynamicHero = dynamic(
  () =>
    import('../../components/full-width-dynamic-hero/full-width-dynamic-hero').then(
      (module) => module.default
    ),
  { ssr: true }
) as typeof FullWidthDynamicHeroComponent;

const FullWidthHeroImageCarousel = dynamic(
  () =>
    import('../../components/full-width-image-carousel/full-width-image-carousel').then(
      (module) => module.default
    ),
  { ssr: true }
) as typeof FullWidthHeroImageCarouselComponent;

const FullWidthVideo = dynamic(
  () =>
    import('../../components/full-width-video/full-width-video').then((module) => module.default),
  { ssr: true }
) as typeof FullWidthVideoComponent;

const HalfAndHalfCarousel = dynamic(
  () =>
    import('../../components/half-and-half-carousel/HalfAndHalfCarousel').then(
      (module) => module.default
    ),
  {
    ssr: true,
  }
) as typeof HalfAndHalfCarouselComponent;

const Honors = dynamic(
  () => import('../../components/honors/honors').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof HonorsComponent;

const HotelDescription = dynamic(
  () =>
    import('../../components/hotel-description/hotel-description').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof HotelDescriptionComponent;

const HotelPolicies = dynamic(
  () =>
    import('../../components/property/hotel-policies/HotelPolicies').then(
      (module) => module.default
    ),
  {
    ssr: true,
  }
) as typeof HotelPoliciesComponent;

const InspiredHero = dynamic(
  () => import('../../components/inspired-hero/InspiredHero').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof InspiredHeroComponent;

const LocationAndTransportation = dynamic(
  () =>
    import('../../components/location-and-transportation/location-and-transportation').then(
      (module) => module.default
    ),
  {
    ssr: true,
  }
) as typeof LocationAndTransportationComponent;

const PartnershipInfo = dynamic(
  () =>
    import('../../components/partnership-info/partnership-info').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof PartnershipInfoComponent;

const PatchworkGrids = dynamic(
  () => import('../../components/patchwork-grids/PatchworkGrids').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof PatchworkGridsComponent;

const PropertyAlerts = dynamic(
  () => import('../../components/alerts-property').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof PropertyAlertsComponent;

const RoomsOverview = dynamic(
  () => import('../../components/rooms-overview/RoomsOverview').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof RoomsOverviewComponent;

const StayTourVideo = dynamic(
  () => import('../../components/stay-tour-video/stay-tour-video').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof StayTourVideoComponent;

const StickySideBySide = dynamic(
  () =>
    import('../../components/sticky-side-by-side-component/StickySideBySideBaseContainer').then(
      (module) => module.default
    ),
  {
    ssr: true,
  }
) as typeof StickySideBySideComponent;

const TabbedOverview = dynamic(
  () => import('../../components/tabbed-overview/TabbedOverview').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof TabbedOverviewComponent;

const UtilityRail = dynamic(
  () => import('../../components/utility-rail/utility-rail').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof UtilityRailComponent;

const VerticalPolicyTabs = dynamic(
  () =>
    import('../../components/vertical-policy-tabs/VerticalPolicyTabs').then(
      (module) => module.default
    ),
  {
    ssr: true,
  }
) as typeof VerticalPolicyTabsComponent;

const VoucherInfo = dynamic(
  () => import('../../components/voucher-info/voucher-info').then((module) => module.default),
  {
    ssr: true,
  }
) as typeof VoucherInfoComponent;

export const homeComponentNames = {
  CELEBRITY_QUOTE: 'celebrityQuote',
  DESCRIPTION_AMENITIES: 'descriptionAmenities',
  EVENTS_INFO: 'eventsInfo',
  FULL_WIDTH_DYNAMIC_HERO: 'fullWidthDynamicHero',
  FULL_WIDTH_IMAGE_CAROUSEL: 'fullWidthImageCarousel',
  FULL_WIDTH_VIDEO: 'fullWidthVideo',
  HALFANDHALF_CAROUSEL: 'halfAndHalfCarousel',
  HONORS: 'honors',
  HOTEL_DESCRIPTION: 'hotelDescription',
  HOTEL_POLICIES: 'hotelPolicies',
  INSPIRED_HERO: 'inspiredHero',
  LOCATION_AND_TRANSPORTATION: 'locationAndTransportation',
  PARTNERSHIP_INFO: 'partnershipInfo',
  PATCHWORK_GRIDS: 'patchworkGrids',
  PROPERTY_ALERTS: 'propertyAlerts',
  ROOMS_OVERVIEW: 'roomsOverview',
  STAY_TOUR_VIDEO: 'stayTourVideo',
  STICKY_SIDE_BY_SIDE: 'stickySideBySide',
  TABBED_OVERVIEW: 'tabbedOverview',
  UTILITY_RAIL: 'utilityRail',
  VERTICAL_POLICY_TABS: 'verticalPolicyTabs',
  VOUCHER_INFO: 'voucherInfo',
} as const;

export const availableComponents = {
  [homeComponentNames.CELEBRITY_QUOTE]: CelebrityQuote,
  [homeComponentNames.DESCRIPTION_AMENITIES]: DescriptionAmenities,
  [homeComponentNames.EVENTS_INFO]: EventsInfo,
  [homeComponentNames.FULL_WIDTH_DYNAMIC_HERO]: FullWidthDynamicHero,
  [homeComponentNames.FULL_WIDTH_IMAGE_CAROUSEL]: FullWidthHeroImageCarousel,
  [homeComponentNames.FULL_WIDTH_VIDEO]: FullWidthVideo,
  [homeComponentNames.HALFANDHALF_CAROUSEL]: HalfAndHalfCarousel,
  [homeComponentNames.HONORS]: Honors,
  [homeComponentNames.HOTEL_DESCRIPTION]: HotelDescription,
  [homeComponentNames.HOTEL_POLICIES]: HotelPolicies,
  [homeComponentNames.INSPIRED_HERO]: InspiredHero,
  [homeComponentNames.LOCATION_AND_TRANSPORTATION]: LocationAndTransportation,
  [homeComponentNames.PARTNERSHIP_INFO]: PartnershipInfo,
  [homeComponentNames.PATCHWORK_GRIDS]: PatchworkGrids,
  [homeComponentNames.PROPERTY_ALERTS]: PropertyAlerts,
  [homeComponentNames.ROOMS_OVERVIEW]: RoomsOverview,
  [homeComponentNames.STAY_TOUR_VIDEO]: StayTourVideo,
  [homeComponentNames.STICKY_SIDE_BY_SIDE]: StickySideBySide,
  [homeComponentNames.TABBED_OVERVIEW]: TabbedOverview,
  [homeComponentNames.UTILITY_RAIL]: UtilityRail,
  [homeComponentNames.VERTICAL_POLICY_TABS]: VerticalPolicyTabs,
  [homeComponentNames.VOUCHER_INFO]: VoucherInfo,
} as const;

export const componentDataMappers = {
  [homeComponentNames.CELEBRITY_QUOTE]: mapHomePageDataToCelebrityQuote,
  [homeComponentNames.DESCRIPTION_AMENITIES]: mapHomePageDataToDescriptionAmenities,
  [homeComponentNames.EVENTS_INFO]: mapHomePageDataToEventInfo,
  [homeComponentNames.FULL_WIDTH_DYNAMIC_HERO]: mapDataToFullWidthDynamicHero,
  [homeComponentNames.FULL_WIDTH_IMAGE_CAROUSEL]: mapHomePageDataToFullWidthCarousel,
  [homeComponentNames.FULL_WIDTH_VIDEO]: mapHomePageDataToFullWidthVideo,
  [homeComponentNames.HALFANDHALF_CAROUSEL]: mapHomePageDataToHalfAndHalfCarousel,
  [homeComponentNames.HONORS]: mapHomePageDataToHonors,
  [homeComponentNames.HOTEL_DESCRIPTION]: mapHomePageDataToHotelDescription,
  [homeComponentNames.HOTEL_POLICIES]: mapHomePageDataToHotelPolicies,
  [homeComponentNames.INSPIRED_HERO]: mapHomePageDataToInspiredHero,
  [homeComponentNames.LOCATION_AND_TRANSPORTATION]: mapHomePageDataToLocationAndTransportation,
  [homeComponentNames.PARTNERSHIP_INFO]: mapHomePageDataToPartnershipInfo,
  [homeComponentNames.PATCHWORK_GRIDS]: mapHomePageDataToPatchworkGrids,
  [homeComponentNames.PROPERTY_ALERTS]: mapHomePageDataToPropertyAlerts,
  [homeComponentNames.ROOMS_OVERVIEW]: mapHomePageDataToRoomsOverview,
  [homeComponentNames.STAY_TOUR_VIDEO]: mapHomePageDataToStayTourVideo,
  [homeComponentNames.STICKY_SIDE_BY_SIDE]: mapHomePageDataToStickySideBySide,
  [homeComponentNames.TABBED_OVERVIEW]: mapHomePageDataToTabbedOverview,
  [homeComponentNames.UTILITY_RAIL]: mapHomePageDataToUtilityRail,
  [homeComponentNames.VERTICAL_POLICY_TABS]: mapHomePageDataToVerticalPolicyTabs,
  [homeComponentNames.VOUCHER_INFO]: mapHomePageDataToVoucherInfo,
} as const;

export const defaultOrder = [
  homeComponentNames.PROPERTY_ALERTS,
  homeComponentNames.INSPIRED_HERO,
  homeComponentNames.UTILITY_RAIL,
  homeComponentNames.DESCRIPTION_AMENITIES,
  homeComponentNames.ROOMS_OVERVIEW,
  homeComponentNames.HONORS,
  homeComponentNames.STAY_TOUR_VIDEO,
  homeComponentNames.HOTEL_POLICIES,
  homeComponentNames.VOUCHER_INFO,
  homeComponentNames.TABBED_OVERVIEW,
  homeComponentNames.PARTNERSHIP_INFO,
  homeComponentNames.EVENTS_INFO,
  homeComponentNames.LOCATION_AND_TRANSPORTATION,
] as const;

export const filterForHomePageComponents = (unfilteredComponents: string[]): MappedKey[] => {
  const availableComponentNames = Object.values(homeComponentNames) as string[];

  const filteredComponents = unfilteredComponents.filter((componentName) =>
    availableComponentNames.includes(componentName)
  ) as MappedKey[];

  return filteredComponents;
};
