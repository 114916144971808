import '../styles/global.css';
import Head from 'next/head';
import { useRouter } from 'next/router';
import type { AppProps as NextAppProps } from 'next/app';
import type { DehydratedState } from '@tanstack/react-query';
import { appWithTranslation } from '@dx-ui/framework-i18n';
import { useManualPageDynatrace } from '@dx-ui/framework-dynatrace';
import { QueryProvider } from '@dx-ui/framework-react-query';
import { getQueryProviderProps } from '@dx-ui/framework-react-query-env-vars';
import { LocationProvider } from '@dx-ui/framework-location-provider';
import type { OneLinkConfig } from '@dx-ui/framework-i18n';
import type { MakeQueryClientArgs } from '@dx-ui/framework-react-query';
import { publicRuntimeConfig } from '../helpers/constants';
import { SearchProvider } from '../helpers/providers/search/search-provider';
import { ThemeStyles } from '../components/hotel-theme/hotel-theme';
import type { ThemeColors } from '../helpers/themes/customTheme';
import { MetricsFunctionalComponent as MetricsProvider } from '../helpers/metrics/withMetrics';
import type { TTrackingData } from '../helpers/metrics.types';

const APP_NAME = 'dx-property-ui';

const queryProps = getQueryProviderProps();

const STALE_TIME_IN_MS = 432e5; // 12 hours

const reactQueryClientOverrideOptions: MakeQueryClientArgs['reactQueryClientOverrideOptions'] = {
  staleTime: STALE_TIME_IN_MS,
};

type AppProps = NextAppProps<{
  dehydratedQueryState: DehydratedState;
  oneLinkConfig: OneLinkConfig;
  themeColors: ThemeColors | null;
  metrics: TTrackingData;
}> & {
  isEdgeworkerPoc: boolean;
};

function App({ Component, pageProps, router }: AppProps) {
  const routerLocale = useRouter().locale || router.locale || 'en';
  useManualPageDynatrace(router.pathname, APP_NAME);

  return (
    <QueryProvider
      {...queryProps}
      dehydratedQueryState={pageProps.dehydratedQueryState}
      reactQueryClientOverrideOptions={reactQueryClientOverrideOptions}
      appName={APP_NAME}
      oneLinkConfig={pageProps.oneLinkConfig}
      routerLocale={routerLocale}
    >
      {publicRuntimeConfig.CONDUCTRICS_URI ? (
        <Head>
          <script src={publicRuntimeConfig.CONDUCTRICS_URI} async type="text/javascript" />
        </Head>
      ) : null}
      {pageProps?.themeColors ? <ThemeStyles theme={pageProps.themeColors} /> : null}
      <MetricsProvider trackingData={pageProps?.metrics?.trackingData}>
        <LocationProvider api={publicRuntimeConfig?.LOCATION_PROVIDER_API}>
          <SearchProvider>
            <Component {...pageProps} />
          </SearchProvider>
        </LocationProvider>
      </MetricsProvider>
    </QueryProvider>
  );
}

export default appWithTranslation<AppProps>(App);
