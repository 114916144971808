import type { AvailableHomePageData } from '../../helpers/componentMapping/homePageComponents';
import type { TTabbedOverview } from './TabbedOverview';

export const mapHomePageDataToTabbedOverview = ({
  homepageData,
}: AvailableHomePageData): TTabbedOverview => ({
  golfOverview: homepageData?.golf,
  restaurantOverview: homepageData?.restaurantOverview,
  spaOverview: homepageData?.spa,
});
