import type { QueryClient } from '@tanstack/react-query';
import { serverSideFeatureConfigsQuery } from '../generated/queries';
import type { Conditions } from '@dx-shared/rule-engine';
import { RuleEngine } from '@dx-shared/rule-engine';
import type { SupportedLanguage } from './types';

type AppName = string;
type AkamaiName = string;
type Ctyhocn = string;

export type OneLinkConfig =
  | {
      appPaths: Record<AppName, AkamaiName>;
      languages?: Partial<Record<SupportedLanguage, AppName[]>>;
      whitelist?: Record<AppName, Record<SupportedLanguage, Ctyhocn>>;
      conditions: Conditions;
      supportedLanguages: SupportedLanguage[];
    }
  | undefined;
export type OneLinkPageProps = Partial<
  Pick<ReturnType<typeof getOneLinkProps>, 'appName' | 'oneLinkConfig' | 'isOneLink'>
>;

export function shouldIncludeLanguageParam(
  locale: string,
  appName: string,
  url: string,
  oneLinkConfig?: OneLinkConfig | null
) {
  try {
    if (oneLinkConfig && locale) {
      const configLocale = locale.match(/^(zh-hans|zh-hant|[a-z]+)/)?.[0] || 'en';
      const conditions = oneLinkConfig.conditions;
      const ruleEngineResult = conditions
        ? new RuleEngine({
            name: 'onelink-conditions',
            conditions,
          }).run({ language: configLocale, url, appName })
        : oneLinkConfig?.languages?.[configLocale]?.includes(appName) || false;
      return ruleEngineResult;
    }
    return false;
  } catch {
    return false;
  }
}

export function getOneLinkProps(
  ...[locale, appName, url, oneLinkConfig]: Parameters<typeof shouldIncludeLanguageParam>
) {
  const isOneLink = shouldIncludeLanguageParam(locale, appName, url, oneLinkConfig);
  const sstLocale = isOneLink ? 'en' : locale;
  return {
    appName,
    oneLinkConfig,
    sstLocale,
    isOneLink,
  };
}

const CONFIG_NAME = 'config-ui-translate-onelink';

export async function serverSideOneLinkConfig(queryClient: QueryClient) {
  return serverSideFeatureConfigsQuery(queryClient, {
    names: [CONFIG_NAME],
  })?.then((data) => {
    return (
      (data.featureConfigs.find((config) => config.name === CONFIG_NAME)
        ?.config as OneLinkConfig) || null
    );
  });
}
